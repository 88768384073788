import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import Banner from "../components/Banner"

const JobOpeningsVacancySlug = ({ data }) => {
  const { markdownRemark: markup } = data
  const { name, jobDetails, banner } = markup.frontmatter

  return (
    <Layout>
      <Banner data={banner} />
      <section className="JobOpeningsVacancySlug py-3 py-lg-5">
        <div className="container">
          <h2 className="section_heading py-3 text-uppercase">{name}</h2>

          <div className="card">
            <div className="card-body">
              {jobDetails.reportsto && (
                <div>
                  <h6 className="text-primary">Reports to </h6>
                  <ReactMarkdown children={jobDetails.reportsto} />
                </div>
              )}
              {jobDetails.region && (
                <div>
                  <h6 className="text-primary">Region</h6>
                  <ReactMarkdown children={jobDetails.region} />
                </div>
              )}
              {jobDetails.ctc && (
                <div>
                  <h6 className="text-primary">CTC Offered</h6>
                  <ReactMarkdown children={jobDetails.ctc} />
                </div>
              )}
              {jobDetails.stipend && (
                <div>
                  <h6 className="text-primary">Stipend </h6>
                  <ReactMarkdown children={jobDetails.stipend} />
                </div>
              )}
              {jobDetails.duration && (
                <div>
                  <h6 className="text-primary">Duration </h6>
                  <ReactMarkdown children={jobDetails.duration} />
                </div>
              )}
              {jobDetails.joining && (
                <div>
                  <h6 className="text-primary">Joining</h6>
                  <ReactMarkdown children={jobDetails.joining} />
                </div>
              )}
              {jobDetails.vacancies && (
                <div>
                  <h6 className="text-primary">No. Of Vacancies</h6>
                  <ReactMarkdown children={jobDetails.vacancies} />
                </div>
              )}
              {jobDetails.purpose && (
                <div>
                  <h6 className="text-primary">Job purpose</h6>
                  <ReactMarkdown children={jobDetails.purpose} />
                </div>
              )}
              {jobDetails.responsibilities && (
                <div>
                  <h6 className="text-primary">Duties and Responsibilities</h6>
                  <ReactMarkdown children={jobDetails.responsibilities} />
                </div>
              )}
              {jobDetails.education && (
                <div>
                  <h6 className="text-primary">Education</h6>
                  <ReactMarkdown children={jobDetails.education} />
                </div>
              )}
              {jobDetails.experience && (
                <div>
                  <h6 className="text-primary">Experience</h6>
                  <ReactMarkdown children={jobDetails.experience} />
                </div>
              )}
              {jobDetails.technicalskills && (
                <div>
                  <h6 className="text-primary">Technical Skills</h6>
                  <ReactMarkdown children={jobDetails.technicalskills} />
                </div>
              )}
              {jobDetails.softskills && (
                <div>
                  <h6 className="text-primary">Soft Skills</h6>
                  <ReactMarkdown children={jobDetails.softskills} />
                </div>
              )}
              {jobDetails.note && (
                <div>
                  <h6 className="text-primary">Note</h6>
                  <ReactMarkdown children={jobDetails.note} />
                </div>
              )}
            </div>
          </div>

          <div
            className="customContent"
            dangerouslySetInnerHTML={{ __html: markup.html }}
          />

          <div className="text-center pt-2">
            <a
              className="btn btn-sm btn-primary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://erp.credenceanalytics.com/jobs"
            >
              Apply Now
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const JobOpeningsVacancyQuery = graphql`
  query JobOpeningsVacancyQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        jobDetails {
          reportsto
          region
          ctc
          stipend
          duration
          joining
          vacancies
          purpose
          responsibilities
          education
          experience
          technicalskills
          softskills
          note
        }
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
export default JobOpeningsVacancySlug
